import React from "react";

export default function Services() {
  return (
    <main>
      <section className="services">
        <div className="content">
          <h1 className="underline">Service</h1>

          <div className="benefits">
            <p>You get:</p>

            <div className="benefit">
              <i class="fa-solid fa-circle-check"></i>
              <p>100% Hand-Coded Website</p>
            </div>
            <div className="benefit">
              <i class="fa-solid fa-circle-check"></i>
              <p>5 Pages</p>
            </div>
            <div className="benefit">
              <i class="fa-solid fa-circle-check"></i>
              <p>Fully Responsive (Mobile Friendly)</p>
            </div>
            <div className="benefit">
              <i class="fa-solid fa-circle-check"></i>
              <p>SEO Optimized</p>
            </div>
            <div className="benefit">
              <i class="fa-solid fa-circle-check"></i>
              <p>Unlimited Changes</p>
            </div>
            <div className="benefit">
              <i class="fa-solid fa-circle-check"></i>
              <p>Hosting Handled For You</p>
            </div>
            <div className="benefit">
              <i class="fa-solid fa-circle-check"></i>
              <p>ASAP Customer Service</p>
            </div>
            <div className="benefit">
              <i class="fa-solid fa-circle-check"></i>
              <p>Fast Page Speed</p>
            </div>
          </div>

          <div>
            <p>All for:</p>
            <p>$0 down, $150/Month</p>
            <p>Contract: 12 Months Minimum</p>
          </div>

          <a href="/contact" className="hoverbtn">
            Get In Touch
          </a>
        </div>
      </section>
    </main>
  );
}

//hand coded
//contact page with phone number and contact form
