import React from "react";

export default function Footer() {
  return (
    <footer>
      <div className="content">
        <div className="logo">
          <i className="fa-solid fa-tree logo" />
          <div>
            <span>Pennypack Web Designs</span>
          </div>
        </div>
        <p id="copyright">Copyright © 2024 | Pennypack Web Designs | All rights reserved</p>
      </div>
    </footer>
  );
}
