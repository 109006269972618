import React from "react";

export default function Contact() {
  return (
    <main>
      <section className="contact">
        <div className="container">
          <h1 className="underline">Contact</h1>

          <form name="contact" method="POST">
            <input type="hidden" name="form-name" value="contact" />

            <input
              type="text"
              name="name"
              id="name"
              placeholder="Name"
              required
            ></input>
            <input
              type="text"
              name="business"
              id="business"
              placeholder="Business"
              required
            ></input>
            <input
              type="email"
              name="email"
              id="email"
              placeholder="Email Address"
              required
            ></input>
            <input
              type="phone"
              name="phone"
              id="phone"
              placeholder="Phone Number"
              required
              maxLength="10"
            ></input>
            <textarea
              name="message"
              id="message"
              placeholder="Type  your message here..."
            ></textarea>
            <input name="bot-field"></input>
            <button className="hoverbtn">Submit</button>
          </form>
        </div>
      </section>
    </main>
  );
}
