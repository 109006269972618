import React from "react";

export default function Nav() {
  const [clicked, setClicked] = React.useState(false);

  return (
    <nav>
      <div className="leftnavcontainer" onClick={() => (window.location = "/")}>
        <i className="fa-solid fa-tree logo" />
        <div>
          <span id="pennypack">Pennypack</span>
          <span id="webdesigns">Web Designs</span>
        </div>
      </div>

      <div id="links" className={clicked ? "clicked" : ""}>
        <a href="/">Home</a>
        <a href="/about">About</a>
        <a href="/services">Service</a>
        <a href="/contact" id="contact" className="hoverbtn">
          Contact
        </a>
      </div>

      <i
        className="fa-solid fa-bars hamburger"
        onClick={() => setClicked(!clicked)}
      />
    </nav>
  );
}
