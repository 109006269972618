import React from "react";
import image from "./images/image1.jpg";

export default function Home() {
  return (
    <main>
      <section className="landing">
        <div className="left">
          <h1>Your Digital Presence Starts Now.</h1>
          <p>
            We offer 100% hand-coded websites to ensure speedy load times,
            excellent performance, and complete flexibility. It's simple: $0
            down, $150/month.
          </p>
        </div>

        <div className="right">
          <img src={image}></img>

          <a href="/contact" className="hoverbtn">
            Get In Touch
          </a>
        </div>
      </section>
    </main>
  );
}
